import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { initialize } from '@brightcove/studio-components';

import { getLanguage, getLanguageCode, getLanguageRoute, supportedLanguages } from './utils';
import { setContinuity, setSiteConfig, setSiteLanguageInfo } from './store/slices/configSlice';
import { useGetUserContinuityQuery } from './store/services/user';
import { useGetConfigMetaQuery } from './store/services/metadata';
import { selectMaintenanceMode } from './store/selectors/configSelectors';
import { selectUser } from './store/selectors/authSelectors';
import { store } from './store';
import * as ROUTES from './routes/routes';
import VideoDetail from './routes/VideoDetail/VideoDetail';
import Settings from './routes/Settings/Settings';
import SeriesDetailPage from './routes/SeriesDetailPage/SeriesDetailPage';
import SectionDetail from './routes/SectionDetail/SectionDetail';
import Search from './routes/Search/Search';
import MyProfile from './routes/MyProfile/MyProfile';
import LoginPage from './routes/LoginPage/LoginPage';
import Language from './routes/Language/Language';
import Home from './routes/Home/Home';
import Events from './routes/Events/Events';
import EventDetail from './routes/Events/EventDetail';
import ErrorPage from './routes/ErrorPage/ErrorPage';
import DynamicPage from './routes/DynamicPage/DynamicPage';
import AuthCallback from './routes/AuthCallback/AuthCallback';
import About from './routes/About/About';
import { usePreviewToken } from './hooks/usePreviewToken';
import useAdobeAnalytics from './hooks/useAdobeAnalytics';
import ScrollToTop from './helpers/ScrollToTop';
import ServiceMessages from './components/ServiceMessages/ServiceMessages';
import MaintenanceMode from './components/MaintenanceMode/MaintenanceMode';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AuthenticationCheck from './auth/AuthenticationCheck';

initialize();

// minutes * milliseconds
const SERVICE_MESSAGE_INTERVAL = 60000;

const AppRoutes: FC = () => (
  <Routes>
    <Route path={`/:lang?${ROUTES.HOME}`} element={<Home />} />
    <Route path={`/:lang?${ROUTES.MY_PROFILE}`} element={<MyProfile />} />
    <Route path={`/:lang?${ROUTES.SEARCH}`} element={<Search />} />
    <Route path={`/:lang?${ROUTES.SETTINGS}`} element={<Settings />} />
    <Route path={`/:lang?${ROUTES.LANGUAGE}`} element={<Language />} />
    <Route path={`/:lang?${ROUTES.ABOUT}`} element={<About />} />
    <Route path={`/:lang?${ROUTES.VIDEO_DETAIL}/:id/:slug?`} element={<VideoDetail />} />
    <Route path={`/:lang?${ROUTES.SECTION_DETAIL}/:pageId/:sectionId`} element={<SectionDetail />} />
    <Route path={`/:lang?${ROUTES.SERIES_DETAIL}/:id/:slug?`} element={<SeriesDetailPage />} />
    <Route path={`/:lang?${ROUTES.DYNAMIC}`} element={<DynamicPage />} />
    <Route path={`/:lang?${ROUTES.EVENT}/:id/:slug?`} element={<EventDetail />} />
    <Route path={`/:lang?${ROUTES.EVENTS}`} element={<Events />} />
    <Route path={`/:lang?${ROUTES.LOGIN}`} element={<LoginPage />} />
    <Route path={`/:lang?${ROUTES.AUTH_CALLBACK}`} element={<AuthCallback />} />
    <Route path="*" element={<ErrorPage />} />
  </Routes>
);

const AppBody: FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const maintenanceMode = useSelector(selectMaintenanceMode);
  const user = useSelector(selectUser);
  const [currentInterval, setCurrentInterval] = useState<NodeJS.Timeout>();

  const isCallbackRoute = window.location.pathname.split('/').some((val) => val === 'cb');

  const continuity = useGetUserContinuityQuery(
    { params: {} },
    {
      refetchOnMountOrArgChange: true,
      skip: !user,
    }
  );

  const { data, refetch } = useGetConfigMetaQuery(
    { lang: getLanguage() },
    {
      refetchOnMountOrArgChange: true,
      skip: isCallbackRoute,
    }
  );

  useAdobeAnalytics();
  usePreviewToken();

  const verifyLanguage = (): void => {
    const language = getLanguage();

    if (supportedLanguages.some((l) => getLanguageCode(l.value) === language)) {
      const resource = data?.labels;
      const languageRoute = getLanguageRoute(language);
      i18n.changeLanguage(language);
      dispatch(setSiteLanguageInfo(language));
      i18n.addResources(getLanguageCode(language), 'translation', resource);

      if (getLanguageCode(language) !== i18n.resolvedLanguage) {
        i18n.reloadResources([getLanguageCode(language)]);
      }

      if (languageRoute) {
        const currentQuery = location.search;
        const newRoute = `${languageRoute}${currentQuery}`;

        navigate(newRoute, { replace: true }); // tslint:disable-line
      }
    }
  };

  useEffect(() => {
    verifyLanguage();

    if (data) {
      dispatch(setSiteConfig(data));
    }

    if (maintenanceMode) {
      clearInterval(currentInterval);
    }
  }, [data, dispatch, i18n, navigate, location.pathname, data?.labels]);

  useEffect(() => {
    //Exposed i18n instance to window object which can be used by player plugins
    window['netapp_i18n'] = i18n;
    const interval = setInterval(() => {
      if (!isCallbackRoute) {
        refetch();
      }
    }, SERVICE_MESSAGE_INTERVAL);
    setCurrentInterval(interval);
  }, []);

  useEffect(() => {
    if (!isCallbackRoute) {
      refetch();
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const logoutParam = searchParams.get('logout');

    if (logoutParam) {
      window.location.href = decodeURIComponent(logoutParam);
      navigate(ROUTES.HOME);
    }
  }, []);

  useEffect(() => {
    dispatch(setContinuity(continuity.data));
  }, [continuity.data]);

  return (
    <div className="app-layout">
      <ServiceMessages />
      <Header />

      <div className="app-body">
        {maintenanceMode ? (
          <MaintenanceMode maintenanceMode={maintenanceMode} />
        ) : (
          <>
            <AppRoutes />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

const App: FC = () => (
  <Router>
    <ScrollToTop />
    <ReduxProvider store={store}>
      <AuthenticationCheck>
        <AppBody />
      </AuthenticationCheck>
    </ReduxProvider>
  </Router>
);

export default App;
