import { useSelector } from 'react-redux';

import { getSimplifiedItemType } from '../utils';
import { selectSiteBaseUrl } from '../store/selectors/configSelectors';
import { SECTION_DETAIL, SERIES_DETAIL, VIDEO_DETAIL } from '../routes/routes';

const sanitize = (value?: string): string => {
  if (value) return `/${value}`;

  return '';
};

export const useGetPageUrl = ({ id = '', type, pageId = '', urlSafeName = '' }): string | undefined => {
  if (id && type) {
    const siteBaseUrl = useSelector(selectSiteBaseUrl);
    const itemType = getSimplifiedItemType(type);
    const sanitizedSafeName = sanitize(urlSafeName);
    const sanitizedId = sanitize(id);

    switch (itemType) {
      case 'ad':
      case 'episode':
      case 'movie':
      case 'trailer':
        return `${siteBaseUrl}${VIDEO_DETAIL}${sanitizedId}${sanitizedSafeName}`;
      case 'series':
      case 'season':
        return `${siteBaseUrl}${SERIES_DETAIL}${sanitizedId}${sanitizedSafeName}`;
      case 'grid':
      case 'carousel':
        return `${siteBaseUrl}${SECTION_DETAIL}/${pageId}/${id}`;
      default:
        return `${siteBaseUrl}/${itemType}${sanitizedId}${sanitizedSafeName}`;
    }
  }

  return undefined;
};
